import request from '@/api/request.js'

/**
 * @Description: 库区
 */

// 分页查询
export function pageWmsArea(data) {
    return request({
        url: '/api/wmsArea/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsArea(data) {
    return request({
        url: '/api/wmsArea/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsArea(data) {
    return request({
        url: '/api/wmsArea/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsArea(data) {
    return request({
        url: '/api/wmsArea/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsArea(data) {
    return request({
        url: '/api/wmsArea/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsArea(data) {
    return request({
        url: '/api/wmsArea/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsArea(data) {
    return request({
        url: '/api/wmsArea/batchDelete',
        method: 'POST',
        data
    })
}

